import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";

const SecondPage = () => {
  const { t } = useTranslation();
  return (
    <Layout>
      <Seo title="Page two" />
      <h1>Components</h1>
      <p>Welcome to the components page.</p>
    </Layout>
  );
};

export default SecondPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
